import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

const mainContainer = "w-full max-w-screen-lg bg-white rounded-md shadow-md mb-8"
const wrapper = "p-4 xl:p-8"
const container = "flex flex-col lg:flex-row items-start"
const imgWrapper = "w-full lg:w-3/12 flex justify-center lg:justify-left"
const infoWrapper = "w-full lg:w-9/12 lg:ml-8"
const h2Style = "text-3xl lg:text-4xl text-center lg:text-left text-gray-900 font-bold mb-4"
const h3Style = "text-lg lg:text-2xl text-center lg:text-left text-gray-800 font-semibold mb-3"
const pStyle = "text-base text-gray-800 mb-2"
const hrStyle = "mb-6"
const buttonStyle = "flex items-center justify-center text-xs px-5 py-2 mb-3 mx-auto lg:mx-0 lg:mr-3 border border-transparent font-bold rounded-md text-white uppercase tracking-widest bg-red-800 hover:bg-red-900"
const imgStyle = "rounded-md shadow-md mt-2 mb-3 lg:mb-0"

const TeamProfile = ({ name, image, position, email, bio }) => {
  return (
    <div className={mainContainer}>
      <div className={wrapper}>
        <h2 className={h2Style}>{name}</h2>

        <hr className={hrStyle} />

        <div className={container}>
          <div className={imgWrapper}>
            <GatsbyImage image={image} layout="fixed" alt={`${name}`} className={imgStyle} />
          </div>
          <div className={infoWrapper}>
            <h3 className={h3Style}>{position}</h3>
            <p>
              <a href={`mailto:${email}`}>
                <button className={buttonStyle}>
                  Send an email
                </button>
              </a>
            </p>
            <p className={pStyle}>{bio}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamProfile