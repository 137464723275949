import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import Layout from '../components/layout';
import ContactSectionNew from '../components/Sections/ContactSectionNew';
import {
  withAuthorization,
} from '../components/Session';
import HeadData from "../data/HeadData"

const ContactPageBase = () => (
  <DashboardWrapper pageName='Contact Us'>
    <div className="relative md:pt-28 2xl:pt-32 pb-32 pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <ContactSectionNew />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const ContactPage = compose(
  withAuthorization(condition),
)(ContactPageBase);

const Contact = () => (
  <Layout>
    <HeadData title='Contact Us' />
    <ContactPage />
  </Layout>
)

export default Contact
