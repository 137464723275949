import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import TeamProfile from '../Common/TEamProfile'

const ContactSectionNew = () => {
  const data = useStaticQuery(graphql`
    query ContactSectionNewQuery {
      prismicContact {
        data {
          team_members {
            photo {
              gatsbyImageData(
                width: 367
              )
            }
            name
            position
            email_address
            short_bio
          }
        }
      }
    }
  `)

  const doc = data.prismicContact

  return (
    <div>
      {doc.data.team_members.map(item => {
        const photo = getImage(item.photo)
        return (
          <TeamProfile
            name={item.name}
            image={photo}
            position={item.position}
            email={item.email_address}
            bio={item.short_bio}
          />
        )
      })}
    </div>
  )
}

export default ContactSectionNew